<template>
  <el-row justify="center">
    <el-col :xs="24" :sm="20" :md="18" :lg="15" :xl="12">
      <el-card class="box-card">
        <template #header>
          <h2>輔大通知小幫手</h2>
          <div id="nav">
            <router-link to="/">註冊</router-link> |
            <router-link to="/update">登入</router-link> |
            <router-link to="/remove">解除通知</router-link>
          </div>
        </template>
        <el-scrollbar height="90%">
          <el-row justify="center">
            <router-view />
          </el-row>
        </el-scrollbar>
      </el-card>
    </el-col>
  </el-row>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 18px;

  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.box-card {
  height: calc(100vh - 40px);
}
</style>
